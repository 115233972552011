import React, { useState, useEffect } from "react";
import axios from "axios";
import { Box, Button, CssBaseline, Container, FormControl, FormLabel, Select, MenuItem, OutlinedInput, Stack, TextField, Typography} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useNavigate } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import Slider from '@mui/material/Slider';
import {fetchCodeDescriptionMap} from '../Utility/getCodeDescription';
import {getString} from '../Utility/getSystemString';
import MessageSection from '../common/message';
import { getUserIdFromToken, getUserTypeFromToken } from "../Utility/tokenParsar";

const MaxQN = 100;
const MinQN = 1;
const questionNums = [
  {
    value: MinQN,
    label: '',
  },
  {
    value: MaxQN,
    label: '',
  },
];

const MaxDuration = 300;
const MinDuration = 1;
const durations = [
  {
    value: MinDuration,
    label: '',
  },
  {
    value: MaxDuration,
    label: '',
  },
];



const MockInterviewCriteriaPage = () => {
    const [loading, setLoading] = useState(false);  // Add loading state
    const navigate = useNavigate();
    const [subject, setSubject] = useState("");
    const [selectedProfessionRole, setSelectedProfessionRole] = useState([]); 
    const [selectedCompetency, setSelectedCompetency] = useState([]); 
    const [selectedSkillSet, setSelectedSkillSet] = useState(''); 
    const [selectedLanguage, setSelectedLanguage] = useState(''); 
    const [selectedQuestionType, setSelectedQuestionType] = useState(''); 
    const [comment, setComment] = useState("");
    const [selectedComplexity, setSelectedComplexity] = useState('');

    const [professionRoles, setProfessionRoles] = useState([]); 
    const [questionTypes, setQuestionTypes] = useState([])
    const [competencies, setCompetencies] = useState([]); 
    const [skillSets, setSkillSets] = useState([]); 
    const [languages, setLanguages] = useState([]); 
    const [distribute, setDistribute] = useState(''); 
    const [complexities, setComplexities] = useState([]);
    
    const [valQuestNum, setQuestNumVal] = React.useState(MinQN);
    const [valDuration, setDurationVal] = React.useState(60);    
    const [numQuestionError, setNumQuestionError] = useState([]);
    const [numQuestionErrorMessage, setNumQuestionErrorMessage] = useState([]);
    const [error, setError] = useState(null); 
        
    useEffect(() => {
        const fetchData = async () => {
            try {
            const complexitiesData = await fetchCodeDescriptionMap('complexity');
            setComplexities(complexitiesData);

            const languagesData = await fetchCodeDescriptionMap('quiz_language');
            setLanguages(languagesData);
    
            const professionRolesData = await fetchCodeDescriptionMap('professional_role');
            setProfessionRoles(professionRolesData);

            const questionTypesData = await fetchCodeDescriptionMap('interview_response_type');
            setQuestionTypes(questionTypesData);

            const competenciesData = await fetchCodeDescriptionMap('competency');
            setCompetencies(competenciesData);

            const skillSetssData = await fetchCodeDescriptionMap('skill_set');
            setSkillSets(skillSetssData);

            setNumQuestionError(false);
            setNumQuestionErrorMessage('');
            setNumQuestionError(false);
            setNumQuestionErrorMessage('');
    
            } catch (error) {
            if (error.response && error.response.data && typeof error.response.data.error === 'string')
                setError(error.response.data.error );
            if(error.status === 401)
                navigate('/logout');
            }
        };
        fetchData();
    }, [navigate]);

    const handleInterviewGeneration = async (e) => {
        e.preventDefault();
        try {
            const formData = new FormData();
            formData.append("subject", subject);
            formData.append("profession_role", selectedProfessionRole);
            formData.append("competency", selectedCompetency);
            formData.append("skill_set", selectedSkillSet);
            formData.append("language_code", selectedLanguage);
            formData.append("status", 'ACTV');
            formData.append("question_type", selectedQuestionType);
            formData.append("duration", valDuration);
            formData.append("num_questions", valQuestNum);
            formData.append("comment", comment);
            formData.append("user_id", getUserIdFromToken());


            const token = localStorage.getItem("token");
            setLoading(true);  // Set loading to true when the process starts
            const currentPage = window.location.pathname; // Get the current page or route
            await axios.post('api/interview/generate', formData, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Page-Name': currentPage,
            }
            }).then(response => {
            
            const interview_id = response.headers['interview_id']
            // Navigate to the results page with the response data
            navigate('/mockinterviewform', { state: { data: response.data, interview_id} });
            })
            .catch(error => {
            if (error.response && error.response.data && typeof error.response.data.error === 'string')
                setError(error.response.data.error );
            if(error.status === 401)
                navigate('/logout');
            });
    
        } catch (error) {
            if (error.response && error.response.data && typeof error.response.data.error === 'string')
            setError(error.response.data.error );
        }
        finally {
            setLoading(false);  // Set loading to false when the process is complete
        }
    };

    const handleQuestionNumberChange = (_, newValue) => {
        setNumQuestionError(false);
        setNumQuestionErrorMessage('');
        if(getUserTypeFromToken() === 'ROV' && newValue > 5)
        {
        setNumQuestionError(true);
        setNumQuestionErrorMessage(getString('ERQF'));
        return;
        }
        setNumQuestionError(false);
        setNumQuestionErrorMessage('');
        setQuestNumVal(newValue);
        
    };
        
    const handleDurationChange = (_, newValue) => {
        setDurationVal(newValue);
    };

    const handleCompetencyChange = async (e) => {
        setSelectedCompetency(e.target.value);
        const token = localStorage.getItem("token");
        const currentPage = window.location.pathname; 
        const config = {
            headers: {
            'Authorization': `Bearer ${token}`,
            'Page-Name': currentPage,
            },
        };
            const prevData = await axios.get(`api/codevalue/getdropdownvalues/role_name=skill_set?dependent_code=${e.target.value}`, config);
            // Update the user data in the state after successful save
            if(prevData.status === 200)
            {
                setSkillSets(prevData.data.codevalues);
            }
        
    };

        

    return (
        <Container direction="column" justify="space-between">
            <CssBaseline enableColorScheme />
            <Stack sx={{ justifyContent: 'top', height: '100dvh', p: 2,}}> 
                <Typography variant="h4" gutterBottom>{getString('MBMI')}</Typography>
                <Box component="form" onSubmit={handleInterviewGeneration} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <FormControl>
                        <FormLabel htmlFor="subject">{getString('GLSB')}</FormLabel>
                        <OutlinedInput
                            id="subject"
                            name="subject"
                            type="string"
                            placeholder={getString('GLSB')}
                            autoComplete="subject"
                            onChange={(e) => setSubject(e.target.value)}
                            required
                            size="small"
                        />
                    </FormControl>

                    <FormControl>
                        <FormLabel htmlFor="selectedNumQuestion" required>{getString('GLNQ')}</FormLabel>
                        <Slider
                            id="selectedNumQuestion"
                            name="selectedNumQuestion"
                            marks={questionNums}
                            step={1}
                            value={valQuestNum}
                            valueLabelDisplay="auto"
                            min={MinQN}
                            max={MaxQN}
                            onChange={handleQuestionNumberChange}
                            error={numQuestionError} 
                            helperText={numQuestionErrorMessage} 
                            color={numQuestionError ? 'error' : 'primary'}
                        />  
                        {numQuestionError && <MessageSection error={numQuestionErrorMessage}/>}
                    </FormControl>

                    <FormControl>
                        <FormLabel htmlFor="duration" required>{getString('ILDR')}</FormLabel>
                        <Slider
                            id="duration"
                            name="duration"
                            marks={durations}
                            step={15}
                            value={valDuration}
                            valueLabelDisplay="auto"
                            min={MinDuration}
                            max={MaxDuration}
                            onChange={handleDurationChange}
                        /> 
                    </FormControl>

                    <FormControl>
                        <FormLabel htmlFor="distributed">{getString('ILDS')}</FormLabel>
                        <OutlinedInput
                            id="distributed"
                            name="distributed"
                            type="string"
                            placeholder={getString('ILDS')}
                            autoComplete="subject"
                            onChange={(e) => setDistribute(e.target.value)}
                            size="small"
                        />
                    </FormControl>

                    <Grid container spacing={3}>
                        <Grid size={{ xs: 'grow', md: 6 }}>
                            <FormControl variant="standard" fullWidth>
                                <FormLabel htmlFor="professionRole" required>{getString('ILPR')}</FormLabel>
                                <Select
                                    labelId="professionRole"
                                    id="professionRole"
                                    value={selectedProfessionRole}
                                    label={getString('ILPR')}
                                    required
                                    onChange={(e) => setSelectedProfessionRole(e.target.value)}
                                >
                                    <MenuItem value=""><em>{getString('ISPR')}</em></MenuItem>
                                    {professionRoles.map((dd) => (
                                        <MenuItem key={dd.code} value={dd.code}>
                                        {dd.description}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid size={{ xs: 'grow', md: 6 }}>
                            <FormControl variant="standard" fullWidth>
                                <FormLabel htmlFor="questionType" required>{getString('ILQT')}</FormLabel>
                                <Select
                                    labelId="questionType"
                                    id="questionType"
                                    value={selectedQuestionType}
                                    label={getString('ILQT')}
                                    required
                                    onChange={(e) => setSelectedQuestionType(e.target.value)}
                                >
                                    <MenuItem value=""><em>{getString('ISQT')}</em></MenuItem>
                                    {questionTypes.map((dd) => (<MenuItem key={dd.code} value={dd.code}>{dd.description}</MenuItem>))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    
                    <Grid container spacing={3}>
                        <Grid size={{ xs: 'grow', md: 6 }}>
                            <FormControl variant="standard" fullWidth>
                                <FormLabel htmlFor="complexity" required>{getString('GLCX')}</FormLabel>
                                <Select
                                    labelId="complexity"
                                    id="complexity"
                                    value={selectedComplexity}
                                    label={getString('GLCX')}
                                    required
                                    onChange={(e) => setSelectedComplexity(e.target.value)}
                                >
                                    <MenuItem value=""><em>{getString('ILC')}</em></MenuItem>
                                    {complexities.map((dd) => (
                                        <MenuItem key={dd.code} value={dd.code}>
                                        {dd.description}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid size={{ xs: 'grow', md: 6 }}>
                            <FormControl variant="standard" fullWidth>
                                <FormLabel htmlFor="language_code" required>{getString('GLLN')}</FormLabel>
                                <Select
                                    labelId="language_code"
                                    id="language_code"
                                    value={selectedLanguage}
                                    label={getString('GLLN')}
                                    required
                                    onChange={(e) => setSelectedLanguage(e.target.value)}
                                >
                                    <MenuItem value=""><em>{getString('ILSL')}</em></MenuItem>
                                    {languages.map((dd) => (<MenuItem key={dd.code} value={dd.code}>{dd.description}</MenuItem>))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid> 

                    <Grid container spacing={3}>
                        <Grid size={{ xs: 'grow', md: 6 }}>
                            <FormControl variant="standard" fullWidth>
                                <FormLabel htmlFor="competency" required>{getString('ILCO')}</FormLabel>
                                <Select
                                    labelId="competency"
                                    id="competency"
                                    value={selectedCompetency}
                                    label={getString('ILCO')}
                                    required
                                    onChange={handleCompetencyChange}
                                >
                                    <MenuItem value=""><em>{getString('ISCO')}</em></MenuItem>
                                    {competencies.map((dd) => (
                                        <MenuItem key={dd.code} value={dd.code}>
                                        {dd.description}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid size={{ xs: 'grow', md: 6 }}>
                            <FormControl variant="standard" fullWidth>
                                <FormLabel htmlFor="skillset" required>{getString('ILSk')}</FormLabel>
                                <Select
                                    labelId="skillset"
                                    id="skillset"
                                    value={selectedSkillSet}
                                    label={getString('ILSk')}
                                    required
                                    onChange={(e) => setSelectedSkillSet(e.target.value)}
                                >
                                    <MenuItem value=""><em>{getString('ISSK')}</em></MenuItem>
                                    {skillSets.map((dd) => (<MenuItem key={dd.code} value={dd.code}>{dd.description}</MenuItem>))}
                                </Select>
                            </FormControl>
                        </Grid>
                        {error && <MessageSection error={getString(error)} />}
                    </Grid>

                    <Grid container spacing={3}>
                        <Grid size={{ xs: 'grow', md: 12 }} offset="auto">
                            <FormControl variant="standard">  
                                <Box sx={{ '& button': { m: 1 } }}>                                  
                                    {/* If loading, show CircularProgress, otherwise show Save button */}
                                    {
                                        loading ? (<CircularProgress size={24}/>) : 
                                        (
                                        <Button variant="contained" size="small" type="submit" color="primary" > {getString('GQGQ')} </Button>
                                        )
                                    }
                                </Box>   
                            </FormControl> 
                            
                        </Grid>
                    </Grid>

                </Box>
            </Stack>
\
        </Container>
    );
};

export default MockInterviewCriteriaPage;