import axios from 'axios';

const getClientCountry = async () => {
  try {
    // Call the ip-api service
    const response = await axios.get('http://ip-api.com/json/');
    
    if (response.data && response.data.country) {
      return response.data.country; // Returns the country name
    } else {
      throw new Error('Country information not available');
    }
  } catch (error) {
    console.error('Error fetching client country:', error);
    return 'Unknown'; // Fallback if there's an error
  }
};

export default getClientCountry;
