import * as React from 'react';
import axios from "axios";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, CssBaseline, Container, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Switch, TextField, Typography, MenuItem } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid2';
import { styled } from '@mui/system';
import { DataGrid } from '@mui/x-data-grid';
import { getFormatedDate} from '../Utility/dateUtils';
import {fetchCodeDescriptionMap, getCodeDescription, fetchTenantNames} from '../Utility/getCodeDescription';
import { isValidEmail, isValidPhone, isRequiredFiledIsEmpty } from "../Utility/validations"
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import {getString} from '../Utility/getSystemString';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MessageSection from '../common/message';


const FormGrid = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

const UserListPage = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);  // State to hold the data from the backend
  const [loading, setLoading] = useState(true);  // State to handle loading
  const [error, setError] = useState(null);  
  const [info, setInfo] = useState(null);  
  const [success, setSuccess] = useState(null);  
  const [modelError, setModelError] = useState(null);
  const [statuses, setStatus] = useState([]);
  const [tenants, setTenants] = useState([]);
  const [roles, setRoles] = useState([]);
  const [types, setTypes] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [countryCodes, setCountryCodes] = useState([]);

  const [selectedUser, setSelectedUser] = useState(null); 
  const [isViewEditModalOpen, setIsViewEditModalOpen] = useState(false); 
  const [modelUsername, setModelUsername] = useState('');
  const [modelLogin, setModelLogin] = useState(''); 
  const [modelCreated, setModelCreated] = useState(''); 
  const [modelLastUpdate, setModelLastUpdate] = useState(''); 
  const [modelTenant, setModelTenant] = useState(''); 
  const [modelUserType, setModelUserType] = useState('');
  const [modelRole, setModelRole] = useState('');
  const [modelStatus, setModelStatus] = useState('');
  const [modelLanguage, setModelLanguage] = useState('');
  const [modelCountryCode, setModelCountryCode] = useState('');
  const [modelPhone, setModelPhone] = useState('');
  const [modelPhoto, setModelPhoto] = useState('');
  const [modelPhotoFileName, setModelPhotoFileName] = useState('');
  const [modelVerified, setModelVerified] = useState('');

  const [modelAddress1, setModelAddress1] = useState('');
  const [modelAddress2, setModelAddress2] = useState('');
  const [modelPostalCode, setModelPostalCode] = useState('');
  const [modelCity, setModelCity] = useState('');
  const [modelCountry, setModelCountry] = useState('');

  const [isReadOnly, setIsReadOnly] = useState(false); 
  const [isNewRecord, setIsNewRecord] = useState(false);

  const [emailError, setEmailError] = React.useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = React.useState('');
  const [nameError, setNameError] = React.useState(false);
  const [nameErrorMessage, setNameErrorMessage] = React.useState('');
  const [tenantError, setTenantError] = React.useState(false);
  const [tenantErrorMessage, setTenantErrorMessage] = React.useState('');
  const [phoneError, setPhoneError] = React.useState(false);
  const [phoneErrorMessage, setPhoneErrorMessage] = React.useState('');
  const [languageError, setLanguageError] = React.useState(false);
  const [languageErrorMessage, setLanguageErrorMessage] = React.useState('');
  const [countryCodeError, setCountryCodeError] = React.useState(false);
  const [countryCodeErrorMessage, setCountryCodeErrorMessage] = React.useState('');
  const [countryError, setCountryError] = React.useState(false);
  const [countryErrorMessage, setCountryErrorMessage] = React.useState('');
  const [cityError, setCityError] = React.useState(false);
  const [cityErrorMessage, setCityErrorMessage] = React.useState('');
  const [postalCodeError, setPostalCodeError] = React.useState(false);
  const [postalCodeErrorMessage, setPostalCodeErrorMessage] = React.useState('');
  const [address1Error, setAddress1Error] = React.useState(false);
  const [address1ErrorMessage, setAddress1ErrorMessage] = React.useState('');

  const [filters, setFilters] = useState({
    user_type: '',
    role: '',
    user_status: 'ACTV',
    user_name: '',
    user_id:''
  });

  useEffect(() => {
    // Function to fetch data from the backend
    const fetchData = async () => {
      try {
        setModelError(false);
        setError(false);
        setInfo(false);
        setSuccess(false);
        const token = localStorage.getItem("token");
        const currentPage = window.location.pathname; // Get the current page or route
        const config = {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
            'Page-Name': currentPage,
          },
        };
        const apiUrl = `api/user/users?user_type=${filters.user_type}&role=${filters.role}&status=${filters.user_status}&username=${filters.user_name}`;
        const response = await axios.get(apiUrl, config);
        setData(response.data.users);

        const statusesData = await fetchCodeDescriptionMap('status');
        setStatus(statusesData);

        const rolesData = await fetchCodeDescriptionMap('user_role');
        setRoles(rolesData);

        const typesData = await fetchCodeDescriptionMap('user_type');
        setTypes(typesData);

        const languageData = await fetchCodeDescriptionMap('user_language');
        setLanguages(languageData);

        const countryCodeData = await fetchCodeDescriptionMap('country_code');
        setCountryCodes(countryCodeData);

        const tenantData = await fetchTenantNames();
        setTenants(tenantData);

      } catch (error) {
          if (error.response && error.response.data && typeof error.response.data.error === 'string') {
            setError(error.response.data.error );
          }
          if(error.status === 401)
            navigate('/logout');
        } finally {
          setLoading(false);  // Stop the loading spinner
        }
    };

    fetchData();
  }, [filters, navigate]);

  // Handle click for "View" (read-only mode)
  const handleViewClick = (user) => {
    setTenantError(false);
    setNameError(false);
    setEmailError(false);
    setPhoneError(false);
    setLanguageError(false);
    setCountryCodeError(false);
    setAddress1Error(false);
    setCityError(false);
    setPostalCodeError(false);
    setCountryError(false);
    setModelError(false);
    setError(false);
    setInfo(false);
    setSuccess(false);
    setLanguageErrorMessage('');
    setPhoneErrorMessage('');
    setEmailErrorMessage('');
    setNameErrorMessage('');
    setCountryCodeErrorMessage('');
    setAddress1ErrorMessage('');
    setPostalCodeErrorMessage('');
    setCityErrorMessage('');
    setCountryErrorMessage('');
    setSelectedUser(user); // Set the selected user for editing
    setModelUsername(user.username); // Pre-fill username in the form
    setModelLogin(user.login); 
    setModelUserType(user.hUserType); 
    setModelRole(user.hRole);
    setModelStatus(user.hStatus);
    setModelLanguage(user.huser_language);
    setModelCountryCode(user.hCountryCode);
    setModelPhone(user.phone);
    setModelVerified(user.hVerified);
    setModelPhotoFileName(user.photo_filename);
    setModelCreated(user.create_date);
    setModelTenant(user.htenant_id);
    setModelAddress1(user.address_line_1);
    setModelAddress2(user.address_line_2);
    setModelCity(user.city);
    setModelPostalCode(user.postal_code);
    setModelCountry(user.country);
    setModelLastUpdate(user.last_update_date);
    setIsReadOnly(true);  // Open modal in read-only mode
    setIsViewEditModalOpen(true);  // Open the modal
    setIsNewRecord(false);
  };

   // Function to handle Edit button click
   const handleEditClick = (user) => {
    setTenantError(false);
    setNameError(false);
    setEmailError(false);
    setPhoneError(false);
    setLanguageError(false);
    setCountryCodeError(false);
    setAddress1Error(false);
    setCityError(false);
    setPostalCodeError(false);
    setCountryError(false);
    setModelError(false);
    setError(false);
    setInfo(false);
    setSuccess(false);
    setLanguageErrorMessage('');
    setPhoneErrorMessage('');
    setEmailErrorMessage('');
    setNameErrorMessage('');
    setCountryCodeErrorMessage('');
    setAddress1ErrorMessage('');
    setPostalCodeErrorMessage('');
    setCityErrorMessage('');
    setCountryErrorMessage('');
    setSelectedUser(user); // Set the selected user for editing
    setModelUsername(user.username); // Pre-fill username in the form
    setModelLogin(user.login); 
    setModelUserType(user.hUserType); 
    setModelLanguage(user.huser_language);
    setModelCountryCode(user.hCountryCode);
    setModelPhone(user.phone);
    setModelVerified(user.hVerified);
    setModelPhotoFileName(user.photo_filename);
    setModelRole(user.hRole);
    setModelCreated(user.create_date);
    setModelTenant(user.htenant_id);
    setModelAddress1(user.address_line_1);
    setModelAddress2(user.address_line_2);
    setModelCity(user.city);
    setModelPostalCode(user.postal_code);
    setModelCountry(user.country);
    setModelLastUpdate(user.last_update_date);
    setModelStatus(user.hStatus);
    setIsReadOnly(false);
    setIsViewEditModalOpen(true); // Open the modal
    setIsNewRecord(false);
  };

  // Function to handle add button click
  const handleAddClick = () => {
    setModelError(false);
    setError(false);
    setInfo(false);
    setSuccess(false);
    setSelectedUser(null); 
    setModelUsername(''); 
    setModelLogin(''); 
    setModelUserType('TECH'); 
    setModelLanguage('');
    setModelCountryCode('');
    setModelPhone('');
    setModelVerified('');
    setModelPhotoFileName('');
    setModelRole('USR');
    setModelCreated('');
    setModelTenant('');
    setModelLastUpdate('');
    setModelStatus('ACTV');
    setTenantError(false);
    setNameError(false);
    setEmailError(false);
    setPhoneError(false);
    setLanguageError(false);
    setCountryCodeError(false);
    setLanguageErrorMessage('');
    setPhoneErrorMessage('');
    setEmailErrorMessage('');
    setNameErrorMessage('');
    setAddress1Error(false);
    setCityError(false);
    setPostalCodeError(false);
    setCountryError(false);
    setAddress1ErrorMessage('');
    setPostalCodeErrorMessage('');
    setCityErrorMessage('');
    setCountryErrorMessage('');
    setCountryCodeErrorMessage('');
    setIsReadOnly(false);
    setIsViewEditModalOpen(true); // Open the modal
    setIsNewRecord(true); 
  };

  // Function to handle delete button click
  const handleDeleteClick =  async (user) => {
    setSelectedUser(user); // Set the selected user for editing
    try {
          setModelError(false);
          setError(false);
          setInfo(false);
          setSuccess(false);
          const token = localStorage.getItem("token");
          const currentPage = window.location.pathname;
          const config = {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
              'Page-Name': currentPage,
            },
          };

          const response  = await axios.delete(`api/user/delete/user_id=${user.user_id}`, config);
          
          const apiUrl = `api/user/users?user_type=${filters.user_type}&role=${filters.role}&status=${filters.user_status}&username=${filters.user_name}`;
          const newData = await axios.get(apiUrl, config);
          setData(newData.data.users);
          if(response.status === 200)
            setSuccess(response.data.msg);
    } catch (error) {
      if (error.response && error.response.data && typeof error.response.data.error === 'string') {
        setError(error.response.data.error );
      }
    }  
  };

  const validateInputs = () => {
    let isValid = true;
    const name = document.getElementById('name');
    const email = document.getElementById('email');
    const phone = document.getElementById('phone');

    const address_line_1 = document.getElementById('address_1');
    const postal_code = document.getElementById('postalcode');
    const city = document.getElementById('city');
    const country = document.getElementById('country');

    if (!isValidEmail(email)) {
      setEmailError(true);
      setEmailErrorMessage(getString('VMEM'));
      isValid = false;
    } else {
      setEmailError(false);
      setEmailErrorMessage('');
    }

    if (!isValidPhone(phone)) {
      setPhoneError(true);
      setPhoneErrorMessage(getString('VMPH'));
      isValid = false;
    } else {
      setPhoneError(false);
      setPhoneErrorMessage('');
    }

    if (!isRequiredFiledIsEmpty(phone)) {
      setPhoneError(true);
      setPhoneErrorMessage(getString('EMFV'));
      isValid = false;
    } else {
      setPhoneError(false);
      setPhoneErrorMessage('');
    }

    if (!modelCountryCode) {
      setCountryCodeError(true);
      setCountryCodeErrorMessage(getString('EMFV'));
      isValid = false;
    } else {
      setCountryCodeError(false);
      setCountryCodeErrorMessage('');
    }
    

    if (!isRequiredFiledIsEmpty(name)) {
      setNameError(true);
      setNameErrorMessage(getString('EMFV'));
      isValid = false;
    } else {
      setNameError(false);
      setNameErrorMessage('');
    }

    if (!(modelTenant.toString && modelTenant.toString.length > 0) ){
      setTenantError(true);
      setTenantErrorMessage(getString('EMFV'));
      isValid = false;
    } else {
      setTenantError(false);
      setTenantErrorMessage('');
    }
    if (!(modelLanguage.length && modelLanguage.length > 0) ){
      setLanguageError(true);
      setLanguageErrorMessage(getString('EMFV'));
      isValid = false;
    } else {
      setLanguageError(false);
      setLanguageErrorMessage('');
    }

    if (!isRequiredFiledIsEmpty(address_line_1)) {
      setAddress1Error(true);
      setAddress1ErrorMessage(getString('VADR'));
      isValid = false;
    } else {
      setAddress1Error(false);
      setAddress1ErrorMessage('');
    }
    
    if (!isRequiredFiledIsEmpty(postal_code)) {
      setPostalCodeError(true);
      setPostalCodeErrorMessage(getString('VPCR'));
      isValid = false;
    } else {
      setPostalCodeError(false);
      setPostalCodeErrorMessage('');
    }

    if (!isRequiredFiledIsEmpty(city)) {
      setCityError(true);
      setCityErrorMessage(getString('VCYR'));
      isValid = false;
    } else {
      setCityError(false);
      setCityErrorMessage('');
    }

    if (!isRequiredFiledIsEmpty(country)) {
      setCountryError(true);
      setCountryErrorMessage(getString('VCR'));
      isValid = false;
    } else {
      setCountryError(false);
      setCountryErrorMessage('');
    }

    return isValid;
  };

  // Function to handle modal form submission (Save changes)
  const handleSaveChanges = async () => {
    var canCloseDialog=true;
    if(validateInputs())
    {
      const newUser = {
        username: modelUsername,
        login: modelLogin,
        user_type: modelUserType, 
        role: modelRole,
        status: modelStatus,
        user_language: modelLanguage,
        country_code: modelCountryCode,
        phone: modelPhone,
        verified: modelVerified,
        tenant_id: modelTenant,
        photo_filename:modelPhotoFileName,
        file: modelPhoto,
        address_line_1:modelAddress1,
        address_line_2:modelAddress2,
        city:modelCity,
        postal_code:modelPostalCode,
        country:modelCountry,
      };
  // Perform API call or state update to save changes
      const updatedUser = {
        ...selectedUser,
        username: modelUsername,
        login: modelLogin,
        user_type: modelUserType, 
        role: modelRole,
        status: modelStatus,
        user_language: modelLanguage,
        country_code: modelCountryCode,
        phone: modelPhone,
        verified: modelVerified,
        photo_filename:modelPhotoFileName,
        file: modelPhoto,
        tenant_id: modelTenant,
        address_line_1:modelAddress1,
        address_line_2:modelAddress2,
        city:modelCity,
        postal_code:modelPostalCode,
        country:modelCountry,
      };
      try {
        const formData = new FormData();
        formData.append("file", modelPhoto);
        formData.append("login", modelLogin);
        formData.append("username", modelUsername);
        formData.append("user_type", modelUserType);
        formData.append("role", modelRole);
        formData.append("status", modelStatus);
        formData.append("verified", modelVerified);
        formData.append("user_language", modelLanguage);
        formData.append("country_code", modelCountryCode);
        formData.append("phone", modelPhone);
        formData.append("tenant_id", modelTenant);
        formData.append("address_line_1", modelAddress1);
        formData.append("address_line_2", modelAddress2);
        formData.append("postal_code", modelPostalCode);
        formData.append("city", modelCity);
        formData.append("country", modelCountry);
          
        const token = localStorage.getItem("token");
        const config = {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        };
        if (isNewRecord) {
          // Save new record
          await axios.post('api/user/create', newUser, config)
            .then((response) => {
              setData((prevData) => [...prevData, response.data.user]);
              if(response.status === 201)
                setSuccess(response.data.msg);
            })
            .catch((error) => {
              canCloseDialog=false;
              if (error.response && error.response.data && typeof error.response.data.error === 'string') {
                setError(error.response.data.error );
                setModelError(error.response.data.error );
              }
              if(error.status === 401)
                navigate('/logout');
            });
        } else {
          canCloseDialog = true;
          const prevData = await axios.patch(`api/user/update/user_id=${selectedUser.user_id}`, formData, config);
          // Update the user data in the state after successful save
          if(prevData.status === 200)
            setSuccess(prevData.data.msg);
          setData((prevData) =>
          prevData.map((user) =>
              user.user_id === selectedUser.user_id ? updatedUser : user
            )
          );
        }
      } catch (error) {
        canCloseDialog=false;
        if (error.response && error.response.data && typeof error.response.data.error === 'string') {
          setError(error.response.data.error );
        }
        if(error.status === 401)
          navigate('/logout');
      }
     
      if(canCloseDialog)
      {
        setIsViewEditModalOpen(false); // Close the modal after saving
        setSelectedUser(null);
        setIsNewRecord(false);

      }
      
    }
  };

  // Render loading spinner or error message
  if (loading) {
    return <div>Loading...</div>;
  }

  const columns = [
    {
      field: 'actions',
      headerName: getString('GLAC'),
      width: 200,
      renderCell: (params) => (
        <>
          {/* View Icon */}
          <IconButton
            onClick={() => handleViewClick(params.row)}
            aria-label="view"
            sx={{ color: '#2e97ff' }}
          >
            <VisibilityIcon />
          </IconButton>
          
          {/* Edit Icon */}
          <IconButton
            onClick={() => handleEditClick(params.row)}
            aria-label="edit"
            sx={{ color: '#2e97ff'}}
          >
            <EditIcon />
          </IconButton>

          {/* Delete Icon */}
          <IconButton
            onClick={() => handleDeleteClick(params.row)}
            aria-label="delete"
            sx={{ color: '#2e97ff'}}
          >
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
    {
      field: "photo",
      headerName: getString('GLPO'),
      width: 100,
      renderCell: (params) => (
        <img
          src={params.value}
          alt="User"
          style={{
            width: "40px",         // Width of the circle
            height: "40px",        // Height of the circle
            objectFit: "cover",    // Ensures the image fits within the circle
            borderRadius: "50%",   // Makes the image circular
          }}
        />
      ),
    },

    { field: 'login', headerName: getString('GLLI'), width: 230, editable: false },
    { field: 'username', headerName: getString('GLNM'), width: 200, editable: false },
    { field: 'status', headerName: getString('GLS'), width: 160, editable: true },
    { field: 'role', headerName: getString('GLRL'), width: 150, editable: false },
    { field: 'user_type', headerName: getString('GLUT'), width: 200, editable: false },
  ];

  // Convert the data object into an array for DataGrid rows
  //const rows = data.map((user, index) => ({
  const rows = data ? data.map((user, index) => ({
    id: user.user_id,                      // A unique ID for each row                     
    login: user.login,              
    username: user.username,            
    status: getCodeDescription(user.status,statuses),
    hStatus: user.status,
    role: getCodeDescription(user.role,roles),   
    hRole: user.role,           
    user_type: getCodeDescription(user.user_type,types), 
    hUserType: user.user_type,    
    huser_language: user.user_language,  
    hCountryCode: user.country_code,
    phone: user.phone,
    photo: user.photo_url,
    photo_filename:user.photo_filename,
    hVerified: null?0:user.verified,      
    create_date: getFormatedDate(user.create_date),
    last_update_date: getFormatedDate(user.last_update_date), 
    tenant_id: getCodeDescription(user.tenant_id,tenants),
    htenant_id: user.tenant_id,
    user_id: user.user_id,
    address_line_1: user.address_line_1,
    address_line_2: user.address_line_2,
    postal_code:user.postal_code,
    city:user.city,
    country:user.country,
  })) : [];

  // Handle form submit to set filters
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      // Check if the file is an image
      const fileType = selectedFile.type;
      const validImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];

      if (validImageTypes.includes(fileType)) {
        setModelPhoto(selectedFile);
      } else {
        alert(getString('UFPE'));
      }
    }
  };

  return (
    <Container>
        <CssBaseline enableColorScheme />
        <Typography variant="h4" gutterBottom>{getString('ULST')}</Typography>
        <Accordion>
        <AccordionSummary expandIcon={<ArrowDropDownIcon />} aria-controls="panel1-content" id="panel1-header">
           <Typography>{getString('GLFL')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3}>
            <FormGrid size={{ xs: 12, md: 3 }}>
              <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                <TextField select label={getString('GLUT')} name="user_type" value={filters.user_type} onChange={handleFilterChange} fullWidth >
                  {types.map((dd) => (
                    <MenuItem key={dd.code} value={dd.code}>
                      {dd.description}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </FormGrid>
              
            <FormGrid size={{ xs: 12, md: 3 }}>
              <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                <TextField select label={getString('GLRL')} name="role" value={filters.role} onChange={handleFilterChange} fullWidth >
                  {roles.map((dd) => (
                    <MenuItem key={dd.code} value={dd.code}>
                      {dd.description}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </FormGrid>

            <FormGrid size={{ xs: 12, md: 3 }}>
              <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                <TextField select label={getString('GLS')} name="user_status" value={filters.user_status} onChange={handleFilterChange} fullWidth >
                    {statuses.map((dd) => (
                      <MenuItem key={dd.code} value={dd.code}>
                        {dd.description}
                      </MenuItem>
                    ))}
                </TextField>
              </FormControl>
            </FormGrid>

            <FormGrid size={{ xs: 12, md: 3 }}>
              <FormControl variant="standard" sx={{ m: 1, minWidth: 160 }}>
                <TextField
                    label={getString('GLNM')}
                    name="user_name"
                    value={filters.user_name}
                    onChange={handleFilterChange}
                    fullWidth
                  />
              </FormControl>
            </FormGrid>
          </Grid>  
        </AccordionDetails>
      </Accordion>
      <IconButton color="primary" onClick={() => handleAddClick()}> <AddIcon fontSize="large"/> </IconButton>
      
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box>
            <DataGrid
              rows={rows}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 5,
                  },
                },
              }}
              pageSizeOptions={[5]}
              disableRowSelectionOnClick

              sx={{
                '& .MuiDataGrid-columnHeaderTitle': {
                  fontWeight: 'bold', // Bold font for the header titles
                }
              }}
            />
          </Box>      
        </Grid>       
      </Grid>
      {error && <MessageSection error={getString(error)} />}
      {info && <MessageSection info={getString(info)} />}
      {success && <MessageSection success={getString(success)} />}

      {/* View/ Edit User Modal */}
      <Dialog open={isViewEditModalOpen} onClose={() => setIsViewEditModalOpen(false)}>
      <DialogTitle>{isReadOnly ? getString('USUD') : getString('USEU')}</DialogTitle>
        <DialogContent>
          <TextField required name='tenant' id='tenant' select label={getString('GLTN')} fullWidth margin="normal" value={modelTenant} onChange={(e) => setModelTenant(e.target.value)} error={tenantError} helperText={tenantErrorMessage} color={tenantError ? 'error' : 'primary'} InputProps={{ readOnly: isReadOnly,}}>
            {tenants.map((dd) => (
              <MenuItem key={dd.code} value={dd.code}>
                {dd.description}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            label={getString('GLNM')}
            fullWidth
            name="name"
            id="name"
            margin="normal"
            value={modelUsername}
            required
            onChange={(e) => setModelUsername(e.target.value)}
            InputProps={{ readOnly: isReadOnly,}}
            error={nameError}
            helperText={nameErrorMessage}
            color={nameError ? 'error' : 'primary'}
          />
          <TextField label={getString('GLLI')} name="email" id="email"fullWidth margin="normal" value={modelLogin} onChange={(e) => setModelLogin(e.target.value)} required error={emailError} helperText={emailErrorMessage} color={nameError ? 'error' : 'primary'}  InputProps={{ readOnly: !isNewRecord,}} />
          
          <TextField select label={getString('GLUT')} fullWidth margin="normal" value={modelUserType} onChange={(e) => setModelUserType(e.target.value)} required  InputProps={{ readOnly: isReadOnly,}}>
            {types.map((dd) => (
              <MenuItem key={dd.code} value={dd.code}>
                {dd.description}
              </MenuItem>
            ))}
          </TextField>
          <TextField select label={getString('GLRL')} fullWidth  margin="normal" value={modelRole} onChange={(e) => setModelRole(e.target.value)} required  InputProps={{ readOnly: isReadOnly,}}>
            {roles.map((dd) => (
              <MenuItem key={dd.code} value={dd.code}>
                {dd.description}
              </MenuItem>
            ))}
          </TextField>
          <TextField select label={getString('GLS')} fullWidth  margin="normal" value={modelStatus} onChange={(e) => setModelStatus(e.target.value)} required  InputProps={{ readOnly: isReadOnly,}}>
            {statuses.map((dd) => (
              <MenuItem key={dd.code} value={dd.code}>
                {dd.description}
              </MenuItem>
            ))}
          </TextField>
          <TextField id='language' name='language' select label={getString('GLLN')} fullWidth  margin="normal" value={modelLanguage} onChange={(e) => setModelLanguage(e.target.value)} required error={languageError} helperText={languageErrorMessage} color={languageError ? 'error' : 'primary'} InputProps={{ readOnly: isReadOnly,}}>
            {languages.map((dd) => (
              <MenuItem key={dd.code} value={dd.code}>
                {dd.description}
              </MenuItem>
            ))}
          </TextField>
          <TextField required select label={getString('GLCC')} fullWidth  margin="normal" value={modelCountryCode} onChange={(e) => setModelCountryCode(e.target.value)}error={countryCodeError} helperText={countryCodeErrorMessage} color={countryCodeError ? 'error' : 'primary'} InputProps={{ readOnly: isReadOnly,}}>
            {countryCodes.map((dd) => (
              <MenuItem key={dd.code} value={dd.code}>
                {dd.description}
              </MenuItem>
            ))}
          </TextField>
          <TextField required label={getString('GLPH')} fullWidth margin="normal" value={modelPhone} 
            name="phone" id="phone" error={phoneError}
            helperText={phoneErrorMessage}
            color={phoneError ? 'error' : 'primary'}
            onChange={(e) => setModelPhone(e.target.value)} 
            />

          <TextField required label={getString('LAD1')} fullWidth name="address_1" id="address_1" margin="normal" value={modelAddress1} onChange={(e) => setModelAddress1(e.target.value)} InputProps={{ readOnly: isReadOnly,}} error={address1Error} helperText={address1ErrorMessage} color={nameError ? 'error' : 'primary'} />
          <TextField          label={getString('LAD2')} fullWidth name="address_2" id="address_2" margin="normal" value={modelAddress2} onChange={(e) => setModelAddress2(e.target.value)} InputProps={{ readOnly: isReadOnly,}} />
          <TextField required label={getString('LADC')} fullWidth name="city" id="city" margin="normal" value={modelCity} onChange={(e) => setModelCity(e.target.value)} InputProps={{ readOnly: isReadOnly,}} error={cityError} helperText={cityErrorMessage} color={nameError ? 'error' : 'primary'}/>
          <TextField required label={getString('LADP')} fullWidth name="postalcode" id="postalcode" margin="normal" value={modelPostalCode} onChange={(e) => setModelPostalCode(e.target.value)} InputProps={{ readOnly: isReadOnly,}} error={postalCodeError} helperText={postalCodeErrorMessage} color={nameError ? 'error' : 'primary'} />
          <TextField required label={getString('LAC')}  fullWidth name="country" id="country" margin="normal" value={modelCountry} onChange={(e) => setModelCountry(e.target.value)} InputProps={{ readOnly: isReadOnly,}} error={countryError} helperText={countryErrorMessage} color={nameError ? 'error' : 'primary'} />
          
          <Switch label={getString('GLVF')} checked={modelVerified} onChange={(e) => setModelVerified(e.target.value)} inputProps={{ 'aria-label': 'controlled' }} />
          <TextField label={getString('GLPO')} fullWidth margin="normal" value={modelPhotoFileName} disabled='true'/>
          {!isReadOnly && ( <input type="file" onChange={(e) => handleFileChange(e)}  />)}
          
          {isReadOnly && (<TextField label={getString('GLCD')} fullWidth margin="normal" value={modelCreated} />)}
          {isReadOnly && (<TextField label={getString('GLLU')} fullWidth margin="normal" value={modelLastUpdate} />)}  
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsViewEditModalOpen(false)}>{getString('GNCL')}</Button>
          {!isReadOnly && (
            <Button onClick={handleSaveChanges} variant="contained">
              {getString('GLSV')}
            </Button>
          )}
        </DialogActions>
        {modelError && <MessageSection error={getString(modelError)} />}
      </Dialog>
          
    </Container>
);
};

export default UserListPage;
