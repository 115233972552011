import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Box, Button, Checkbox, Container, FormControl, FormControlLabel, FormLabel, Link, Stack, TextField, Typography } from '@mui/material';
import ForgotPassword from './ForgotPassword';
import MessageSection from '../common/message';
import {getUserIdFromToken} from '../Utility/tokenParsar';



const Login = () => {
  const [emailError, setEmailError] = React.useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = React.useState('');
  const [passwordError, setPasswordError] = React.useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    setError('');
    e.preventDefault();
    try {
      setEmailError(false);
      setEmailErrorMessage('');
      setPasswordError(false);
      setPasswordErrorMessage('');
      if(open)
        return;
      // Use Flask endpoints instead
      const response = await axios.post('api/auth/login', { login, password, open });
      localStorage.setItem("token", response.data.access_token);
      
      // Set up the headers including Authorization and Page-Name
      const config = {
        headers: {
          'Authorization': `Bearer ${response.data.access_token}`,
          'Content-Type': 'application/json',
          'Page-Name': '/dashboard',  // Pass the current page name in a custom header
        },
      };
      const cachedData = localStorage.getItem('system_string');
      const cachedCodeData = localStorage.getItem('code_table_data');

      if (cachedData) {
          
      }
      else{
        const MetaDataResponse = await axios.get('api/metadata/getsystemstrings', config);
        const data = await MetaDataResponse;
        localStorage.setItem('system_string', JSON.stringify(data));
      }

      if (cachedCodeData) {
          
      }
      else{
        const codeTableResponse = await axios.get('api/metadata/getcodetabledescription', config);
        const codeTabledata = await codeTableResponse;
        localStorage.setItem('code_table_data', JSON.stringify(codeTabledata));
      }

      

      const apiUserUrl = `api/user/users?user_id=${getUserIdFromToken()}`;
      // Make the request to the backend with the headers
      const userResponse = await axios.get(apiUserUrl,config);
      localStorage.setItem('login_user', JSON.stringify(userResponse));
      

      navigate('/dashboard'); // Redirect to dashboard after login
    } catch (error) {
        if(error.response.data.type === 'login')
        {
          setEmailError(true);
          setEmailErrorMessage(error.response.data.error);
        }
        if(error.response.data.type === 'password')
        {
           setPasswordError(true);
           setPasswordErrorMessage(error.response.data.error);
       }
       else
        {
          if(error.response && error.response.data && error.response.data.error && typeof error.response.data.error === 'string')
            setError(error.response.data.error)
          else
            setError('Unknown Error');
        }      
    }
  };

  return (
    <Container maxWidth="sm" direction="column" justify="space-between">
      <Stack sx={{
              justifyContent: 'center',
              height: '100dvh',
              p: 2,
            }}>
        <Typography component="h1" variant="h4" sx={{ width: '100%', fontSize: 'clamp(2rem, 10vw, 2.15rem)' }}>Sign in</Typography>
        <Box
          component="form"
          onSubmit={handleLogin}
          noValidate
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            gap: 2,
          }}
        >
          <FormControl>
            <FormLabel htmlFor="login">Login</FormLabel>
              <TextField
                error={emailError}
                helperText={emailErrorMessage}
                id="login"
                type="email"
                name="login"
                placeholder="your@email.com"
                autoComplete="email"
                onChange={(e) => setLogin(e.target.value)}
                autoFocus
                required
                fullWidth
                variant="outlined"
                color={emailError ? 'error' : 'primary'}
                sx={{ ariaLabel: 'email' }}
              />
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="password">Password</FormLabel>
            <TextField
              error={passwordError}
              helperText={passwordErrorMessage}
              name="password"
              placeholder="••••••"
              type="password"
              id="password"
              autoComplete="current-password"
              autoFocus
              required
              fullWidth
              variant="outlined"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              color={passwordError ? 'error' : 'primary'}
            />
          </FormControl>
          <FormControlLabel control={<Checkbox value="remember" color="primary" />} label="Remember me"/>
          <ForgotPassword open={open} handleClose={handleClose} />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            className="submit-btn"
          >
            Login
          </Button>

          <Typography sx={{ textAlign: 'center' }}>
            Don&apos;t have an account?{' '}
            <Box component="span" sx={{ display: 'inline-flex', gap: '1rem' }}>
              <Link
                href="/signup"
                variant="body2"
                sx={{ alignSelf: 'center' }}
              >
                Sign up
              </Link>
            
              <Link
                  component="button"
                  onClick={handleClickOpen}
                  variant="body2"
                  sx={{ alignSelf: 'baseline' }}
                >Forgot your password?
              </Link>
            </Box>
          </Typography>
          <MessageSection error={error} />
        </Box>
      </Stack>
    </Container>
  );
};

export default Login;
