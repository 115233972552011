import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import {Box, Button, Container, CssBaseline, Divider, IconButton, Link, List, ListItem, Paper, Typography }from '@mui/material';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import SearchIcon from '@mui/icons-material/Search';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Free from '../assets/free.png'; 
import Personal from '../assets/personal.png'; 
import Pro from '../assets/pro.png'; 
import Enterprise from '../assets/enterprise.png'; 
import Grid from '@mui/material/Grid2';
import AgkiyaLogo from '../assets/agkiyalogo.png'; 
import {getString} from '../Utility/getSystemString';
import getClientCountry from '../Utility/getClientCountry'; 


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    ...theme.applyStyles('dark', {
      backgroundColor: '#1A2027',
    }),
  }));

const HomePage = () => {
    const [clientCountry, setClientCountry] = useState('');
    const [individualPrice, setIndividualPrice] = useState('');
    const [subscriptionPrice, setSubscriptionPrice] = useState('');

    useEffect(() => {
        const fetchCountry = async () => {
            const country = await getClientCountry();
            setClientCountry(country);
            if(country.toUpperCase() === 'INDIA')
            {
                setIndividualPrice('₹ 59.99');
                setSubscriptionPrice('₹ 199.99');
            }
            else
            {
                setIndividualPrice('1,99 €');
                setSubscriptionPrice('99,99 €');

            }
                

        };
    
        fetchCountry();
      }, []);

  return (
    <div>
        <Box sx={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1000, padding: '10px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', }}>
            <Grid container spacing={3} alignItems="center">
                <Grid size={1}>
                    <Box component="ul" sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', pl: 0, m: 0, listStyle: 'none',}} >
                        <img src={AgkiyaLogo} alt="Agkiya" style={{ width: '50%', height: '50%', cursor: 'pointer', objectFit: 'cover' }} />
                    </Box>
                </Grid>
                <Grid size={9}>
                    <Typography variant="h5" sx={{color:'dodgerblue'}} > Quiz Intello</Typography>

                </Grid>
                <Grid size={2}>
                    <Box sx={{ display: 'flex', gap: 3 }}>
                        <Link href="/signup" variant="body2" sx={{ alignSelf: 'center' }}> Sign up </Link>
                        <Link href="/Login" variant="body2" sx={{ alignSelf: 'center' }} > Log In </Link>
                    </Box>
                </Grid>
            </Grid>  
        </Box>
      
        <Box sx={{ flexDirection: 'column', justifyContent: 'space-between', alignItems: 'stretch', flexGrow: 1,marginTop: '20px', }}>
            <Grid container size={12} justifyContent="center" alignItems="center" style={{ textAlign: "center", height: "100%" }}>
                <Typography variant="h4" gutterBottom> What are you up to today?</Typography>
            </Grid>

            <Grid size={12}>
                <Box
                    sx={{
                    border: '1px solid lightgray',
                    borderRadius: 2,
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: 2,
                    }}
                >
                    <Grid size={2}>
                        <Box
                            sx={{
                            border: '1px solid lightgray',
                            borderRadius: 2,
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: 2,
                            }}
                        >
                            <Typography variant="h6">Create a Quiz</Typography>
                        </Box>
                    </Grid>
                    <Grid size={10}>
                        <Box
                            sx={{
                        
                            borderRadius: 2,
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: 2,
                            }}
                        >
                            <Grid container spacing={1}>
                                <Grid size={3}>
                                    <Grid>
                                        <Item>
                                            <Box component="ul" sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', pl: 0, m: 0, listStyle: 'none',}} >
                                                <img src={Free} alt="Free Subscription" style={{ width: '20%', height: '20%', cursor: 'pointer', objectFit: 'cover' }} />
                                            </Box>
                                        </Item>
                                    </Grid>
                                    <Divider />
                                    <Grid>
                                        <Item>
                                            <Box component="ul" sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', pl: 0, m: 0, listStyle: 'none',}} >
                                            {getString('Explore the QuizIntello as a rover. For personal use.')}
                                            </Box>
                                        </Item>
                                    </Grid>
                                    <Divider />
                                    <Grid>
                                        <Item>
                                            <Box component="ul" sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', pl: 0, m: 0, listStyle: 'none',}} >
                                                <Typography variant="h5" gutterBottom>{getString('FREE')}</Typography>
                                            </Box>
                                        </Item>
                                    </Grid>
                                    <Divider /> 
                                    <Grid>
                                        <Item>
                                            <Box component="ul" sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', pl: 0, m: 0, listStyle: 'none',}} >

                                                <List sx={{ width: '100%', bgcolor: 'background.paper', mb: 2 }}>
                                                    <ListItem alignItems="flex-start" sx={{paddingLeft: 0, paddingRight: 0,}}>
                                                        <ListItemIcon sx={{ color: '#2e97ff', minWidth: 'auto', marginRight: 1, fontSize: { xs: '1.2rem', sm: '1.5rem', md: '2rem' },}}><CheckCircleRoundedIcon /></ListItemIcon>
                                                        <ListItemText secondary={<React.Fragment>{getString('1 MB file limit to generate the quiz.')}</React.Fragment>}/>
                                                    </ListItem>
                                                    <ListItem alignItems="flex-start" sx={{paddingLeft: 0, paddingRight: 0,}}>
                                                        <ListItemIcon sx={{ color: '#2e97ff', minWidth: 'auto', marginRight: 1, fontSize: { xs: '1.2rem', sm: '1.5rem', md: '2rem' },}}><CheckCircleRoundedIcon /></ListItemIcon>
                                                        <ListItemText secondary={<React.Fragment>{getString('Creation of quizzes up to 5 questions.')}</React.Fragment>}/>
                                                    </ListItem>
                                                    <ListItem alignItems="flex-start" sx={{paddingLeft: 0, paddingRight: 0,}}>
                                                        <ListItemIcon sx={{ color: '#2e97ff', minWidth: 'auto', marginRight: 1, fontSize: { xs: '1.2rem', sm: '1.5rem', md: '2rem' },}}><CheckCircleRoundedIcon /></ListItemIcon>
                                                        <ListItemText secondary={<React.Fragment>{getString('Each quiz can be shared with 1 participant.')}</React.Fragment>}/>
                                                    </ListItem>

                                                    <ListItem alignItems="flex-start" sx={{paddingLeft: 0, paddingRight: 0,}}>
                                                        <ListItemIcon sx={{ color: '#2e97ff', minWidth: 'auto', marginRight: 1, fontSize: { xs: '1.2rem', sm: '1.5rem', md: '2rem' },}}><CheckCircleRoundedIcon /></ListItemIcon>
                                                        <ListItemText secondary={<React.Fragment>{getString('2 Quizzes per day.')}</React.Fragment>}/>
                                                    </ListItem>
                                                    <ListItem alignItems="flex-start" sx={{paddingLeft: 0, paddingRight: 0,}}>
                                                        <ListItemIcon sx={{ color: '#2e97ff', minWidth: 'auto', marginRight: 1, fontSize: { xs: '1.2rem', sm: '1.5rem', md: '2rem' },}}><CheckCircleRoundedIcon /></ListItemIcon>
                                                        <ListItemText secondary={<React.Fragment>{getString('No Validity Limit.')}</React.Fragment>}/>
                                                    </ListItem>
                                                </List>
                                            </Box>
                                        </Item>
                                    </Grid>
                                    <Divider /> 
                                </Grid>
                                <Grid size={3}>
                                    <Grid>
                                        <Item>
                                            <Box component="ul" sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', pl: 0, m: 0, listStyle: 'none',}} >
                                                <img src={Personal} alt="Individual Subscription" style={{ width: '20%', height: '20%', cursor: 'pointer', objectFit: 'cover' }} />
                                            </Box>
                                        </Item>
                                    </Grid>
                                    <Divider />
                                    <Grid>
                                        <Item>
                                            <Box component="ul" sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', pl: 0, m: 0, listStyle: 'none',}} >
                                            {getString('For individual user who run small scale business.')}
                                            </Box>
                                        </Item>
                                    </Grid>
                                    <Divider />
                                    <Grid>
                                        <Item>
                                            <Box component="ul" sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', pl: 0, m: 0, listStyle: 'none',}} >
                                                <Typography variant="h5" gutterBottom>{getString(individualPrice)}</Typography>
                                            </Box>
                                        </Item>
                                    </Grid>
                                    <Divider /> 
                                    <Grid>
                                        <Item>
                                            <Box component="ul" sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', pl: 0, m: 0, listStyle: 'none',}} >

                                                <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                                                    <ListItem alignItems="flex-start" sx={{paddingLeft: 0, paddingRight: 0,}}>
                                                        <ListItemIcon sx={{ color: '#2e97ff', minWidth: 'auto', marginRight: 1, fontSize: { xs: '1.2rem', sm: '1.5rem', md: '2rem' },}}><CheckCircleRoundedIcon /></ListItemIcon>
                                                        <ListItemText secondary={<React.Fragment>{getString('5 MB file limit to generate the quiz.')}</React.Fragment>}/>
                                                    </ListItem>
                                                    <ListItem alignItems="flex-start" sx={{paddingLeft: 0, paddingRight: 0,}}>
                                                        <ListItemIcon sx={{ color: '#2e97ff', minWidth: 'auto', marginRight: 1, fontSize: { xs: '1.2rem', sm: '1.5rem', md: '2rem' },}}><CheckCircleRoundedIcon /></ListItemIcon>
                                                        <ListItemText secondary={<React.Fragment>{getString('Creation of quizzes up to 25 questions.')}</React.Fragment>}/>
                                                    </ListItem>
                                                    <ListItem alignItems="flex-start" sx={{paddingLeft: 0, paddingRight: 0,}}>
                                                        <ListItemIcon sx={{ color: '#2e97ff', minWidth: 'auto', marginRight: 1, fontSize: { xs: '1.2rem', sm: '1.5rem', md: '2rem' },}}><CheckCircleRoundedIcon /></ListItemIcon>
                                                        <ListItemText secondary={<React.Fragment>{getString('Each quiz can be shared with 10 participant.ISL')}</React.Fragment>}/>
                                                    </ListItem>
                                                    <ListItem alignItems="flex-start" sx={{paddingLeft: 0, paddingRight: 0,}}>
                                                        <ListItemIcon sx={{ color: '#2e97ff', minWidth: 'auto', marginRight: 1, fontSize: { xs: '1.2rem', sm: '1.5rem', md: '2rem' },}}><CheckCircleRoundedIcon /></ListItemIcon>
                                                        <ListItemText secondary={<React.Fragment>{getString('1 Quiz per day.')}</React.Fragment>}/>
                                                    </ListItem>
                                                    <ListItem alignItems="flex-start" sx={{paddingLeft: 0, paddingRight: 0,}}>
                                                        <ListItemIcon sx={{ color: '#2e97ff', minWidth: 'auto', marginRight: 1, fontSize: { xs: '1.2rem', sm: '1.5rem', md: '2rem' },}}><CheckCircleRoundedIcon /></ListItemIcon>
                                                        <ListItemText secondary={<React.Fragment>{getString('Valid for day of payment only.')}</React.Fragment>}/>
                                                    </ListItem>
                                                </List>
                                            </Box>
                                        </Item>
                                    </Grid>
                                    <Divider /> 
                                </Grid>

                                <Grid size={3}>
                                    <Grid>
                                        <Item>
                                            <Box component="ul" sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', pl: 0, m: 0, listStyle: 'none',}} >
                                                <img src={Pro} alt="Premium Subscription" style={{ width: '20%', height: '20%', cursor: 'pointer', objectFit: 'cover' }} />
                                            </Box>
                                        </Item>
                                    </Grid>
                                    <Divider />
                                    <Grid>
                                        <Item>
                                            <Box component="ul" sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', pl: 0, m: 0, listStyle: 'none',}} >
                                            {getString('For medium-sized businesses with a limited number of customers.')}
                                            </Box>
                                        </Item>
                                    </Grid>
                                    <Divider />
                                    <Grid>
                                        <Item>
                                            <Box component="ul" sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', pl: 0, m: 0, listStyle: 'none',}} >
                                                <Typography variant="h5" gutterBottom>{getString(subscriptionPrice)}</Typography>
                                            </Box>
                                        </Item>
                                    </Grid>
                                    <Divider /> 
                                    <Grid>
                                        <Item>
                                            <Box component="ul" sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', pl: 0, m: 0, listStyle: 'none',}} >

                                                <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                                                    <ListItem alignItems="flex-start" sx={{paddingLeft: 0, paddingRight: 0,}}>
                                                        <ListItemIcon sx={{ color: '#2e97ff', minWidth: 'auto', marginRight: 1, fontSize: { xs: '1.2rem', sm: '1.5rem', md: '2rem' },}}><CheckCircleRoundedIcon /></ListItemIcon>
                                                        <ListItemText secondary={<React.Fragment>{getString('20 MB file limit to generate the quiz.')}</React.Fragment>}/>
                                                    </ListItem>
                                                    <ListItem alignItems="flex-start" sx={{paddingLeft: 0, paddingRight: 0,}}>
                                                        <ListItemIcon sx={{ color: '#2e97ff', minWidth: 'auto', marginRight: 1, fontSize: { xs: '1.2rem', sm: '1.5rem', md: '2rem' },}}><CheckCircleRoundedIcon /></ListItemIcon>
                                                        <ListItemText secondary={<React.Fragment>{getString('Creation of quizzes up to 50 questions.')}</React.Fragment>}/>
                                                    </ListItem>
                                                    <ListItem alignItems="flex-start" sx={{paddingLeft: 0, paddingRight: 0,}}>
                                                        <ListItemIcon sx={{ color: '#2e97ff', minWidth: 'auto', marginRight: 1, fontSize: { xs: '1.2rem', sm: '1.5rem', md: '2rem' },}}><CheckCircleRoundedIcon /></ListItemIcon>
                                                        <ListItemText secondary={<React.Fragment>{getString('Each quiz can be shared with 50 participant.')}</React.Fragment>}/>
                                                    </ListItem>
                                                    <ListItem alignItems="flex-start" sx={{paddingLeft: 0, paddingRight: 0,}}>
                                                        <ListItemIcon sx={{ color: '#2e97ff', minWidth: 'auto', marginRight: 1, fontSize: { xs: '1.2rem', sm: '1.5rem', md: '2rem' },}}><CheckCircleRoundedIcon /></ListItemIcon>
                                                        <ListItemText secondary={<React.Fragment>{getString('50 Quizzes per day.')}</React.Fragment>}/>
                                                    </ListItem>
                                                    <ListItem alignItems="flex-start" sx={{paddingLeft: 0, paddingRight: 0,}}>
                                                        <ListItemIcon sx={{ color: '#2e97ff', minWidth: 'auto', marginRight: 1, fontSize: { xs: '1.2rem', sm: '1.5rem', md: '2rem' },}}><CheckCircleRoundedIcon /></ListItemIcon>
                                                        <ListItemText secondary={<React.Fragment>{getString('Valid for one month.')}</React.Fragment>}/>
                                                    </ListItem>
                                                </List>
                                            </Box>
                                        </Item>
                                    </Grid>
                                    <Divider /> 
                                </Grid>

                                <Grid size={3}>
                                    <Grid>
                                        <Item>
                                            <Box component="ul" sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', pl: 0, m: 0, listStyle: 'none',}} >
                                                <img src={Enterprise} alt="Enterprise Subscription" style={{ width: '20%', height: '20%', cursor: 'pointer', objectFit: 'cover' }} />
                                            </Box>
                                        </Item>
                                    </Grid>
                                    <Divider />
                                    <Grid>
                                        <Item>
                                            <Box component="ul" sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', pl: 0, m: 0, listStyle: 'none',}} >
                                            {getString('For medium-sized businesses with a limited number of customers.')}
                                            </Box>
                                        </Item>
                                    </Grid>
                                    <Divider />
                                    
                                    <Grid>
                                        <Item>
                                            <Box component="ul" sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', pl: 0, m: 0, listStyle: 'none',}} >

                                                <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                                                    <ListItem alignItems="flex-start" sx={{paddingLeft: 0, paddingRight: 0,}}>
                                                        <ListItemIcon sx={{ color: '#2e97ff', minWidth: 'auto', marginRight: 1, fontSize: { xs: '1.2rem', sm: '1.5rem', md: '2rem' },}}><CheckCircleRoundedIcon /></ListItemIcon>
                                                        <ListItemText secondary={<React.Fragment>{getString('No file limit to generate the quiz.')}</React.Fragment>}/>
                                                    </ListItem>
                                                    <ListItem alignItems="flex-start" sx={{paddingLeft: 0, paddingRight: 0,}}>
                                                        <ListItemIcon sx={{ color: '#2e97ff', minWidth: 'auto', marginRight: 1, fontSize: { xs: '1.2rem', sm: '1.5rem', md: '2rem' },}}><CheckCircleRoundedIcon /></ListItemIcon>
                                                        <ListItemText secondary={<React.Fragment>{getString('Unlimited quiz questions.')}</React.Fragment>}/>
                                                    </ListItem>
                                                    <ListItem alignItems="flex-start" sx={{paddingLeft: 0, paddingRight: 0,}}>
                                                        <ListItemIcon sx={{ color: '#2e97ff', minWidth: 'auto', marginRight: 1, fontSize: { xs: '1.2rem', sm: '1.5rem', md: '2rem' },}}><CheckCircleRoundedIcon /></ListItemIcon>
                                                        <ListItemText secondary={<React.Fragment>{getString('No limit on participants.')}</React.Fragment>}/>
                                                    </ListItem>
                                                    <ListItem alignItems="flex-start" sx={{paddingLeft: 0, paddingRight: 0,}}>
                                                        <ListItemIcon sx={{ color: '#2e97ff', minWidth: 'auto', marginRight: 1, fontSize: { xs: '1.2rem', sm: '1.5rem', md: '2rem' },}}><CheckCircleRoundedIcon /></ListItemIcon>
                                                        <ListItemText secondary={<React.Fragment>{getString('Unlimited quiz creation.')}</React.Fragment>}/>
                                                    </ListItem>
                                                    <ListItem alignItems="flex-start" sx={{paddingLeft: 0, paddingRight: 0,}}>
                                                        <ListItemIcon sx={{ color: '#2e97ff', minWidth: 'auto', marginRight: 1, fontSize: { xs: '1.2rem', sm: '1.5rem', md: '2rem' },}}><CheckCircleRoundedIcon /></ListItemIcon>
                                                        <ListItemText secondary={<React.Fragment>{getString('Validity depend on contract.')}</React.Fragment>}/>
                                                    </ListItem>
                                                    <ListItem alignItems="flex-start" sx={{paddingLeft: 0, paddingRight: 0,}}>
                                                        <ListItemIcon sx={{ color: '#2e97ff', minWidth: 'auto', marginRight: 1, fontSize: { xs: '1.2rem', sm: '1.5rem', md: '2rem' }, }}><CheckCircleRoundedIcon /></ListItemIcon>
                                                        <ListItemText secondary={<React.Fragment>{getString('Product customization as per your business.')}</React.Fragment>}/>
                                                    </ListItem>
                                                </List>
                                            </Box>
                                        </Item>
                                    </Grid>
                                
                                </Grid>
                            </Grid>
                        </Box>                   
                    </Grid>
                </Box>
            </Grid>

            <Grid size={12} sx={{display: 'none'}}>
                <Box
                    sx={{
                    border: '1px solid lightgray',
                    borderRadius: 2,
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: 2,
                    }}
                >
                    <Grid size={2}>
                        <Box
                            sx={{
                            border: '1px solid lightgray',
                            borderRadius: 2,
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: 2,
                            }}
                        >
                            <Typography variant="h6">Prepare for Interview</Typography>
                        </Box>
                    </Grid>
                    <Grid size={10}>
                        <Box
                            sx={{
                        
                            borderRadius: 2,
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: 2,
                            }}
                        >
                            <Grid container spacing={1}>
                                <Grid size={3}>
                                    <Grid>
                                        <Item>
                                            <Box component="ul" sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', pl: 0, m: 0, listStyle: 'none',}} >
                                                <img src={Free} alt="Free Subscription" style={{ width: '20%', height: '20%', cursor: 'pointer', objectFit: 'cover' }} />
                                            </Box>
                                        </Item>
                                    </Grid>
                                    <Divider />
                                    <Grid>
                                        <Item>
                                            <Box component="ul" sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', pl: 0, m: 0, listStyle: 'none',}} >
                                            {getString('Explore the QuizIntello as a rover. For personal use.')}
                                            </Box>
                                        </Item>
                                    </Grid>
                                    <Divider />
                                    <Grid>
                                        <Item>
                                            <Box component="ul" sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', pl: 0, m: 0, listStyle: 'none',}} >
                                                <Typography variant="h5" gutterBottom>{getString('FREE')}</Typography>
                                            </Box>
                                        </Item>
                                    </Grid>
                                    <Divider /> 
                                    <Grid>
                                        <Item>
                                            <Box component="ul" sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', pl: 0, m: 0, listStyle: 'none',}} >

                                                <List sx={{ width: '100%', bgcolor: 'background.paper', mb: 2 }}>
                                                    <ListItem alignItems="flex-start" sx={{paddingLeft: 0, paddingRight: 0,}}>
                                                        <ListItemIcon sx={{ color: '#2e97ff', minWidth: 'auto', marginRight: 1, fontSize: { xs: '1.2rem', sm: '1.5rem', md: '2rem' },}}><CheckCircleRoundedIcon /></ListItemIcon>
                                                        <ListItemText secondary={<React.Fragment>{getString('1 MB file limit to generate the quiz.')}</React.Fragment>}/>
                                                    </ListItem>
                                                    <ListItem alignItems="flex-start" sx={{paddingLeft: 0, paddingRight: 0,}}>
                                                        <ListItemIcon sx={{ color: '#2e97ff', minWidth: 'auto', marginRight: 1, fontSize: { xs: '1.2rem', sm: '1.5rem', md: '2rem' },}}><CheckCircleRoundedIcon /></ListItemIcon>
                                                        <ListItemText secondary={<React.Fragment>{getString('Creation of quizzes up to 5 questions.')}</React.Fragment>}/>
                                                    </ListItem>
                                                    <ListItem alignItems="flex-start" sx={{paddingLeft: 0, paddingRight: 0,}}>
                                                        <ListItemIcon sx={{ color: '#2e97ff', minWidth: 'auto', marginRight: 1, fontSize: { xs: '1.2rem', sm: '1.5rem', md: '2rem' },}}><CheckCircleRoundedIcon /></ListItemIcon>
                                                        <ListItemText secondary={<React.Fragment>{getString('Each quiz can be shared with 1 participant.')}</React.Fragment>}/>
                                                    </ListItem>

                                                    <ListItem alignItems="flex-start" sx={{paddingLeft: 0, paddingRight: 0,}}>
                                                        <ListItemIcon sx={{ color: '#2e97ff', minWidth: 'auto', marginRight: 1, fontSize: { xs: '1.2rem', sm: '1.5rem', md: '2rem' },}}><CheckCircleRoundedIcon /></ListItemIcon>
                                                        <ListItemText secondary={<React.Fragment>{getString('2 Quizzes per day.')}</React.Fragment>}/>
                                                    </ListItem>
                                                    <ListItem alignItems="flex-start" sx={{paddingLeft: 0, paddingRight: 0,}}>
                                                        <ListItemIcon sx={{ color: '#2e97ff', minWidth: 'auto', marginRight: 1, fontSize: { xs: '1.2rem', sm: '1.5rem', md: '2rem' },}}><CheckCircleRoundedIcon /></ListItemIcon>
                                                        <ListItemText secondary={<React.Fragment>{getString('No Validity Limit.')}</React.Fragment>}/>
                                                    </ListItem>
                                                </List>
                                            </Box>
                                        </Item>
                                    </Grid>
                                    <Divider /> 
                                </Grid>
                                <Grid size={3}>
                                    <Grid>
                                        <Item>
                                            <Box component="ul" sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', pl: 0, m: 0, listStyle: 'none',}} >
                                                <img src={Personal} alt="Individual Subscription" style={{ width: '20%', height: '20%', cursor: 'pointer', objectFit: 'cover' }} />
                                            </Box>
                                        </Item>
                                    </Grid>
                                    <Divider />
                                    <Grid>
                                        <Item>
                                            <Box component="ul" sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', pl: 0, m: 0, listStyle: 'none',}} >
                                            {getString('For individual user who run small scale business.')}
                                            </Box>
                                        </Item>
                                    </Grid>
                                    <Divider />
                                    <Grid>
                                        <Item>
                                            <Box component="ul" sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', pl: 0, m: 0, listStyle: 'none',}} >
                                                <Typography variant="h5" gutterBottom>{getString('PriceLabel')}</Typography>
                                            </Box>
                                        </Item>
                                    </Grid>
                                    <Divider /> 
                                    <Grid>
                                        <Item>
                                            <Box component="ul" sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', pl: 0, m: 0, listStyle: 'none',}} >

                                                <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                                                    <ListItem alignItems="flex-start" sx={{paddingLeft: 0, paddingRight: 0,}}>
                                                        <ListItemIcon sx={{ color: '#2e97ff', minWidth: 'auto', marginRight: 1, fontSize: { xs: '1.2rem', sm: '1.5rem', md: '2rem' },}}><CheckCircleRoundedIcon /></ListItemIcon>
                                                        <ListItemText secondary={<React.Fragment>{getString('5 MB file limit to generate the quiz.')}</React.Fragment>}/>
                                                    </ListItem>
                                                    <ListItem alignItems="flex-start" sx={{paddingLeft: 0, paddingRight: 0,}}>
                                                        <ListItemIcon sx={{ color: '#2e97ff', minWidth: 'auto', marginRight: 1, fontSize: { xs: '1.2rem', sm: '1.5rem', md: '2rem' },}}><CheckCircleRoundedIcon /></ListItemIcon>
                                                        <ListItemText secondary={<React.Fragment>{getString('Creation of quizzes up to 25 questions.')}</React.Fragment>}/>
                                                    </ListItem>
                                                    <ListItem alignItems="flex-start" sx={{paddingLeft: 0, paddingRight: 0,}}>
                                                        <ListItemIcon sx={{ color: '#2e97ff', minWidth: 'auto', marginRight: 1, fontSize: { xs: '1.2rem', sm: '1.5rem', md: '2rem' },}}><CheckCircleRoundedIcon /></ListItemIcon>
                                                        <ListItemText secondary={<React.Fragment>{getString('Each quiz can be shared with 10 participant.ISL')}</React.Fragment>}/>
                                                    </ListItem>
                                                    <ListItem alignItems="flex-start" sx={{paddingLeft: 0, paddingRight: 0,}}>
                                                        <ListItemIcon sx={{ color: '#2e97ff', minWidth: 'auto', marginRight: 1, fontSize: { xs: '1.2rem', sm: '1.5rem', md: '2rem' },}}><CheckCircleRoundedIcon /></ListItemIcon>
                                                        <ListItemText secondary={<React.Fragment>{getString('1 Quiz per day.')}</React.Fragment>}/>
                                                    </ListItem>
                                                    <ListItem alignItems="flex-start" sx={{paddingLeft: 0, paddingRight: 0,}}>
                                                        <ListItemIcon sx={{ color: '#2e97ff', minWidth: 'auto', marginRight: 1, fontSize: { xs: '1.2rem', sm: '1.5rem', md: '2rem' },}}><CheckCircleRoundedIcon /></ListItemIcon>
                                                        <ListItemText secondary={<React.Fragment>{getString('Valid for day of payment only.')}</React.Fragment>}/>
                                                    </ListItem>
                                                </List>
                                            </Box>
                                        </Item>
                                    </Grid>
                                    <Divider /> 
                                </Grid>

                                <Grid size={3}>
                                    <Grid>
                                        <Item>
                                            <Box component="ul" sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', pl: 0, m: 0, listStyle: 'none',}} >
                                                <img src={Pro} alt="Premium Subscription" style={{ width: '20%', height: '20%', cursor: 'pointer', objectFit: 'cover' }} />
                                            </Box>
                                        </Item>
                                    </Grid>
                                    <Divider />
                                    <Grid>
                                        <Item>
                                            <Box component="ul" sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', pl: 0, m: 0, listStyle: 'none',}} >
                                            {getString('For medium-sized businesses with a limited number of customers.')}
                                            </Box>
                                        </Item>
                                    </Grid>
                                    <Divider />
                                    <Grid>
                                        <Item>
                                            <Box component="ul" sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', pl: 0, m: 0, listStyle: 'none',}} >
                                                <Typography variant="h5" gutterBottom>{getString('PriceLabel')}</Typography>
                                            </Box>
                                        </Item>
                                    </Grid>
                                    <Divider /> 
                                    <Grid>
                                        <Item>
                                            <Box component="ul" sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', pl: 0, m: 0, listStyle: 'none',}} >

                                                <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                                                    <ListItem alignItems="flex-start" sx={{paddingLeft: 0, paddingRight: 0,}}>
                                                        <ListItemIcon sx={{ color: '#2e97ff', minWidth: 'auto', marginRight: 1, fontSize: { xs: '1.2rem', sm: '1.5rem', md: '2rem' },}}><CheckCircleRoundedIcon /></ListItemIcon>
                                                        <ListItemText secondary={<React.Fragment>{getString('20 MB file limit to generate the quiz.')}</React.Fragment>}/>
                                                    </ListItem>
                                                    <ListItem alignItems="flex-start" sx={{paddingLeft: 0, paddingRight: 0,}}>
                                                        <ListItemIcon sx={{ color: '#2e97ff', minWidth: 'auto', marginRight: 1, fontSize: { xs: '1.2rem', sm: '1.5rem', md: '2rem' },}}><CheckCircleRoundedIcon /></ListItemIcon>
                                                        <ListItemText secondary={<React.Fragment>{getString('Creation of quizzes up to 50 questions.')}</React.Fragment>}/>
                                                    </ListItem>
                                                    <ListItem alignItems="flex-start" sx={{paddingLeft: 0, paddingRight: 0,}}>
                                                        <ListItemIcon sx={{ color: '#2e97ff', minWidth: 'auto', marginRight: 1, fontSize: { xs: '1.2rem', sm: '1.5rem', md: '2rem' },}}><CheckCircleRoundedIcon /></ListItemIcon>
                                                        <ListItemText secondary={<React.Fragment>{getString('Each quiz can be shared with 50 participant.')}</React.Fragment>}/>
                                                    </ListItem>
                                                    <ListItem alignItems="flex-start" sx={{paddingLeft: 0, paddingRight: 0,}}>
                                                        <ListItemIcon sx={{ color: '#2e97ff', minWidth: 'auto', marginRight: 1, fontSize: { xs: '1.2rem', sm: '1.5rem', md: '2rem' },}}><CheckCircleRoundedIcon /></ListItemIcon>
                                                        <ListItemText secondary={<React.Fragment>{getString('50 Quizzes per day.')}</React.Fragment>}/>
                                                    </ListItem>
                                                    <ListItem alignItems="flex-start" sx={{paddingLeft: 0, paddingRight: 0,}}>
                                                        <ListItemIcon sx={{ color: '#2e97ff', minWidth: 'auto', marginRight: 1, fontSize: { xs: '1.2rem', sm: '1.5rem', md: '2rem' },}}><CheckCircleRoundedIcon /></ListItemIcon>
                                                        <ListItemText secondary={<React.Fragment>{getString('Valid for one month.')}</React.Fragment>}/>
                                                    </ListItem>
                                                </List>
                                            </Box>
                                        </Item>
                                    </Grid>
                                    <Divider /> 
                                </Grid>

                                <Grid size={3}>
                                    <Grid>
                                        <Item>
                                            <Box component="ul" sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', pl: 0, m: 0, listStyle: 'none',}} >
                                                <img src={Enterprise} alt="Enterprise Subscription" style={{ width: '20%', height: '20%', cursor: 'pointer', objectFit: 'cover' }} />
                                            </Box>
                                        </Item>
                                    </Grid>
                                    <Divider />
                                    <Grid>
                                        <Item>
                                            <Box component="ul" sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', pl: 0, m: 0, listStyle: 'none',}} >
                                            {getString('For medium-sized businesses with a limited number of customers.')}
                                            </Box>
                                        </Item>
                                    </Grid>
                                    <Divider />
                                    
                                    <Grid>
                                        <Item>
                                            <Box component="ul" sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', pl: 0, m: 0, listStyle: 'none',}} >

                                                <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                                                    <ListItem alignItems="flex-start" sx={{paddingLeft: 0, paddingRight: 0,}}>
                                                        <ListItemIcon sx={{ color: '#2e97ff', minWidth: 'auto', marginRight: 1, fontSize: { xs: '1.2rem', sm: '1.5rem', md: '2rem' },}}><CheckCircleRoundedIcon /></ListItemIcon>
                                                        <ListItemText secondary={<React.Fragment>{getString('No file limit to generate the quiz.')}</React.Fragment>}/>
                                                    </ListItem>
                                                    <ListItem alignItems="flex-start" sx={{paddingLeft: 0, paddingRight: 0,}}>
                                                        <ListItemIcon sx={{ color: '#2e97ff', minWidth: 'auto', marginRight: 1, fontSize: { xs: '1.2rem', sm: '1.5rem', md: '2rem' },}}><CheckCircleRoundedIcon /></ListItemIcon>
                                                        <ListItemText secondary={<React.Fragment>{getString('Unlimited quiz questions.')}</React.Fragment>}/>
                                                    </ListItem>
                                                    <ListItem alignItems="flex-start" sx={{paddingLeft: 0, paddingRight: 0,}}>
                                                        <ListItemIcon sx={{ color: '#2e97ff', minWidth: 'auto', marginRight: 1, fontSize: { xs: '1.2rem', sm: '1.5rem', md: '2rem' },}}><CheckCircleRoundedIcon /></ListItemIcon>
                                                        <ListItemText secondary={<React.Fragment>{getString('No limit on participants.')}</React.Fragment>}/>
                                                    </ListItem>
                                                    <ListItem alignItems="flex-start" sx={{paddingLeft: 0, paddingRight: 0,}}>
                                                        <ListItemIcon sx={{ color: '#2e97ff', minWidth: 'auto', marginRight: 1, fontSize: { xs: '1.2rem', sm: '1.5rem', md: '2rem' },}}><CheckCircleRoundedIcon /></ListItemIcon>
                                                        <ListItemText secondary={<React.Fragment>{getString('Unlimited quiz creation.')}</React.Fragment>}/>
                                                    </ListItem>
                                                    <ListItem alignItems="flex-start" sx={{paddingLeft: 0, paddingRight: 0,}}>
                                                        <ListItemIcon sx={{ color: '#2e97ff', minWidth: 'auto', marginRight: 1, fontSize: { xs: '1.2rem', sm: '1.5rem', md: '2rem' },}}><CheckCircleRoundedIcon /></ListItemIcon>
                                                        <ListItemText secondary={<React.Fragment>{getString('Validity depend on contract.')}</React.Fragment>}/>
                                                    </ListItem>
                                                    <ListItem alignItems="flex-start" sx={{paddingLeft: 0, paddingRight: 0,}}>
                                                        <ListItemIcon sx={{ color: '#2e97ff', minWidth: 'auto', marginRight: 1, fontSize: { xs: '1.2rem', sm: '1.5rem', md: '2rem' }, }}><CheckCircleRoundedIcon /></ListItemIcon>
                                                        <ListItemText secondary={<React.Fragment>{getString('Product customization as per your business.')}</React.Fragment>}/>
                                                    </ListItem>
                                                </List>
                                            </Box>
                                        </Item>
                                    </Grid>
                                
                                </Grid>
                            </Grid>
                        </Box>                   
                    </Grid>
                </Box>
            </Grid>

        </Box>

    </div>

    

      
    
  );
};

export default HomePage;
