import React, { useState, useEffect } from "react";
import axios from "axios";
import { Box, Button, CssBaseline, Container, FormControl, FormLabel, Select, MenuItem, OutlinedInput, Stack, TextField, Typography} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useNavigate } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import {fetchCodeDescriptionMap} from '../Utility/getCodeDescription';
import {getString} from '../Utility/getSystemString';
import MessageSection from '../common/message';
import { getUserRoleFromToken } from "../Utility/tokenParsar";


const UploadContentPage = () => {
    const [loading, setLoading] = useState(false);  // Add loading state
    const navigate = useNavigate();

    const [categories, setCategories] = useState([]); 
    const [statuses, setStatuses] = useState([]);  

    const [file, setFile] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(''); 
    const [selectedSubCategory, setSelectedSubCategory] = useState(''); 
    const [selectedStatus, setSelectedStatus] = useState('');
    const [subject, setSubject] = useState("");
    const [content, setContent] = useState("");
    const [fileError, setFileError] = React.useState(false);
    const [fileErrorMessage, setFileErrorMessage] = React.useState('');

    const [error, setError] = useState(null);  

    useEffect(() => {
        const fetchData = async () => {
            try {
            const statusesData = await fetchCodeDescriptionMap('status');
            setStatuses(statusesData);

            const CategoriesData = await fetchCodeDescriptionMap('interview_category');
            setCategories(CategoriesData);

        } catch (error) {
        if (error.response && error.response.data && typeof error.response.data.error === 'string')
            setError(error.response.data.error );
        if(error.status === 401)
            navigate('/logout');
        }
    };
    fetchData();
    }, [navigate]);

      const handleKnowledgeBaseGeneration = async (e) => {
        e.preventDefault();
        try {
        }
        catch (error) {
            if (error.response && error.response.data && typeof error.response.data.error === 'string')
              setError(error.response.data.error );
          }
          finally {
            setLoading(false);  // Set loading to false when the process is complete
          }
      
          
        };

        const handlFileChange = (e) => {
            setError(false);
            setFileError(false);
            setFileErrorMessage('');
            const selectedFile = e.target.files[0];
            const maxSizeInBytes =  1024 * 1024 * 1024;
            
            if (selectedFile) {
              // Check if the file is an image
              const fileType = selectedFile.type;
              const supportedFileType = ['application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/msword',  'application/vnd.oasis.opendocument.text',  'text/rtf', 'text/plain','application/pdf','image/jpeg','image/jpg','image/png'];
        
              if (!supportedFileType.includes(fileType)) {
                setFileError(true);
                setFileErrorMessage(getString('UFFE'));
                e.target.value = ''; // Clear the file input field
                setFile(null);
                return;
              }
              if (selectedFile.size > maxSizeInBytes) {
                setFileError(true);
                setFileErrorMessage(getString('ERUF'));
                e.target.value = ''; // Clear the file input field
                setFile(null);
                return;
              }
              // If file type and size are valid, reset errors and store the file
              setFileError(false);
              setFileErrorMessage('');
              setFile(selectedFile);
            }
            else{
              alert(getString('UFFE'));
              setFileError(true);
              setFileErrorMessage(getString('UFFE'));
              setFile(null);
            }
          };



    return (
       <Container direction="column" justify="space-between">
            <CssBaseline enableColorScheme />
            <Stack sx={{ justifyContent: 'top', height: '100dvh', p: 2,}}>
                <Typography variant="h4" gutterBottom>{getString('PLUF')}</Typography>
                <Box component="form" onSubmit={handleKnowledgeBaseGeneration} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <FormControl>
                        <FormLabel htmlFor="fileUpload" required>{getString('QLUF')}</FormLabel>
                            <OutlinedInput
                                id="fileUpload" name="fileUpload" required size="small"
                                onChange={handlFileChange}
                                type="file"
                                placeholder="fileUpload"
                                autoComplete="fileUpload"
                                error={fileError} 
                                helperText={fileErrorMessage} 
                                color={fileError ? 'error' : 'primary'}
                            />
                            {fileError && <MessageSection error={fileErrorMessage}/>}
                    </FormControl>

                    <FormControl>
                        <FormLabel htmlFor="content">{getString('ILCN')}</FormLabel>
                        <OutlinedInput
                            id="content"
                            placeholder={getString('ILCN')}
                            onChange={(e) => setContent(e.target.value)}
                            multiline
                            rows="5"
                        />
                    </FormControl>

                    <FormControl>
                        <FormLabel htmlFor="subject">{getString('GLSB')}</FormLabel>
                        <OutlinedInput
                        id="subject"
                        name="subject"
                        type="subject"
                        placeholder={getString('GLSB')}
                        autoComplete="subject"
                        onChange={(e) => setSubject(e.target.value)}
                        required
                        size="small"
                        />
                    </FormControl>

                    


                    <FormControl variant="standard" fullWidth>
                        <FormLabel htmlFor="category_code" required>{getString('ILCO')}</FormLabel>
                        <Select
                        labelId="category_code"
                        id="category_code"
                        value={selectedCategory}
                        label={getString('ILCO')}
                        required
                        onChange={(e) => selectedCategory(e.target.value)}
                        >
                        <MenuItem value=""><em>{getString('ISCO')}</em></MenuItem>
                        {categories.map((dd) => (
                            <MenuItem key={dd.code} value={dd.code}>{dd.description}</MenuItem>
                        ))}
                        </Select>
                    </FormControl>

                    <FormControl variant="standard" fullWidth>
                        <FormLabel htmlFor="sub_category_code" required>{getString('ILSk')}</FormLabel>
                        <Select
                        labelId="sub_category_code"
                        id="sub_category_code"
                        value={selectedSubCategory}
                        label={getString('ILSk')}
                        required
                        onChange={(e) => selectedSubCategory(e.target.value)}
                        >
                        <MenuItem value=""><em>{getString('ISSK')}</em></MenuItem>
                        {categories.map((dd) => (
                            <MenuItem key={dd.code} value={dd.code}>{dd.description}</MenuItem>
                        ))}
                        </Select>
                    </FormControl>

                    <Grid container spacing={3}>
                        <Grid size={{ xs: 'grow', md: 12 }} offset="auto">
                            <FormControl variant="standard">  
                            <Box sx={{ '& button': { m: 1 } }}>                                  
                                {/* If loading, show CircularProgress, otherwise show Save button */}
                                {
                                loading ? (<CircularProgress size={24}/>) : 
                                (
                                    <Button variant="contained" size="small" type="submit" color="primary" > {getString('GQGQ')} </Button>
                                )
                                }
                            </Box>   
                            </FormControl> 
                
                        </Grid>
                    </Grid>

                </Box>
            </Stack>
        </Container>
    );
};

export default UploadContentPage;