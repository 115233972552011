import * as React from 'react';
import {Container, CssBaseline, Typography} from '@mui/material';
import {getString} from '../Utility/getSystemString';


const InterviewHistoryPage = () => {
    return (
        <Container>
            <CssBaseline enableColorScheme />
            <Typography variant="h4" gutterBottom>{getString('MBHY')}</Typography>
            <Typography variant="h4" gutterBottom>{getString('Comiing Soon.....')}</Typography>
        </Container>
    );
};

export default InterviewHistoryPage;