import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import ProtectedRoute from './components/Utility/ProtectedRoute';
import LoginPage from "./components/Login/Login";
import LogoutPage from "./components/Login/logout";
import SignUpPage from "./components/Login/Signup";
import QuizCreationPage from "./components/Quiz/QuizGenerator";
import QuizFormPage from "./components/Quiz/QuizForm";
import QuizListPage from "./components/Quiz/quizlist";
import QuizResultPage from "./components/Quiz/quizresult";
import QuizSharingPage from "./components/admin/quizshareinglist";
import QuizQuestListPage from "./components/Quiz/quizquestionlist";
import QuizQuestSheetPage from "./components/Quiz/quizquestionsheet";
import QuizResponseEndPage from "./components/Quiz/quizresponseend";
import UserListPage from "./components/user/userlist";
import CostReportPage from "./components/admin/costreport";
import BillingReportPage from "./components/admin/billingreport"
import QuizResponseReportPage from "./components/admin/quizresponsereport";
import DashboardLayoutBasic from "./components/dashboard/dashboard";
import DashboardPage from "./components/dashboard/dashboardcontent";
import ParticipantsListPage from "./components/Quiz/participants";
import SharingGroupListPage from "./components/Quiz/sharinggroup";
import SharingGroupParticipantsListPage from "./components/Quiz/sharinggroupparticipants";
import TenantListPage from "./components/admin/tenant";
import SubscriptionPage from "./components/user/subscription";
import PersonalDetailsPage from "./components/user/personaldetails";
import InterviewHistoryPage from "./components/joobseeker/interviewhistory";
import InterviewResultPage from "./components/joobseeker/interviewresult";
import MockInterviewCriteriaPage from "./components/joobseeker/mockinterviewcriteria";
import ProgressReportPage from "./components/joobseeker/progressreport";
import FaqPage from "./components/common/faq";
import HelpPage from "./components/common/help";
import PrivacyPolicyPage from "./components/common/privacypolicy";
import UploadContentPage from "./components/admin/uploadcontent"
import HomePage from "./components/common/home";

import "./components/styles.css"


function App() {
  return (
    <Router>
      <Routes>
        {/* Redirect the root path "/" to "/dashboard" */}
        <Route path="/" element={<HomePage />} /> {/* Default route */}
        {/*<Route path="/dashboard" element={<Navigate to="/dashboard" replace />} */}

        {/* unprotected routes  */}
        <Route path="/login" element={<LoginPage />} />
        <Route path="/logout" element={<LogoutPage />} />
        <Route path="/signup" element={<SignUpPage />} />
        <Route path="/quizquestionsheet" element={<QuizQuestSheetPage />} />
        <Route path="/quizresponseend" element={<QuizResponseEndPage />} />

        {/* Wrap all protected routes inside the DashboardLayoutBasic */}
        <Route
          path="/*"
          element={
            <ProtectedRoute>
              <DashboardLayoutBasic />
            </ProtectedRoute>
          }
        >
          {/* Protected child routes rendered inside DashboardLayoutBasic */}
          <Route path="dashboard" element={<DashboardPage />} />
          {/* Routes for User */}
          <Route path="createquiz" element={<QuizCreationPage />} />
          <Route path="quizform" element={<QuizFormPage />} />
          <Route path="quizlist" element={<QuizListPage />} />
          <Route path="quizquestionlist" element={<QuizQuestListPage />} />
          <Route path="quizresult" element={<QuizResultPage />} />
          {/* Routes for Admin */}
          <Route path="userlist" element={<UserListPage />} />
          <Route path="quizshareinglist" element={<QuizSharingPage />} />
          <Route path="costreport" element={<CostReportPage />} />
          <Route path="reports/costreport" element={<CostReportPage />} />
          <Route path="billingreport" element={<BillingReportPage />} />
          <Route path="quizresponsereport" element={<QuizResponseReportPage />} />
          <Route path="participants" element={<ParticipantsListPage />} />
          <Route path="sharinggroup" element={<SharingGroupListPage />} />
          <Route path="sharinggroupparticipants" element={<SharingGroupParticipantsListPage />} />
          <Route path="tenant" element={<TenantListPage />} />
          <Route path="uploadcontent" element={<UploadContentPage />} />

          {/* Routes for Common All User */}
          <Route path="subscription" element={<SubscriptionPage />} />
          <Route path="personaldetails" element={<PersonalDetailsPage />} />
          

          {/* Routes for Jobseeker */}
          <Route path="interviewhistory" element={<InterviewHistoryPage />} />
          <Route path="interviewresult" element={<InterviewResultPage />} />
          <Route path="mockinterviewcriteria" element={<MockInterviewCriteriaPage />} />
          <Route path="progressreport" element={<ProgressReportPage />} />
          <Route path="faq" element={<FaqPage />} />
          <Route path="help" element={<HelpPage />} />
          <Route path="privacypolicy" element={<PrivacyPolicyPage />} />

        </Route>

        {/* Fallback route for undefined paths (404) */}
        <Route path="*" element={<h2>404 - Page Not Found</h2>} />
      </Routes>
    </Router>
  );
}

export default App;
